body,
html {
  height: 100%;
}

img {
  max-width: 100%;
}

body {
  @include respons-xs {
    padding-top: 60px;
  }
}

*:focus {
  outline: none !important;
}

a.link {
  color: $colorPeach;
  transition: all 0.4s ease;;
  &:hover {
    color: $colorBlack;
  }
}

.container-fluid {
  min-height: 100%;
  padding-bottom: 500px;
  margin-bottom: -500px;
  @include respons-md {
    padding-bottom: 400px;
    margin-bottom: -400px;
  }
  @include respons-sm {
    padding-bottom: 300px;
    margin-bottom: -300px;
  }
  @include respons-xs {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
