.nav {
  float: right;
  @include respons-xs {
    float: none;
    position: absolute;
    top: 60px;
    left: 100%;
    width: 100%;
    text-align: center;
    background: $colorWhite;
    z-index: 100;
    padding: 25px 15px;
    opacity: 0;
    @include box-shadow(0, 3px, 2px, rgba(0,0,0,0.1));
    @include hp_transition();

    &.open {
      z-index: 9999;
      left: 0;
      opacity: 1;
    }
  }

  &__item {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    margin: 45px 12px;
    line-height: 25px;
    display: inline-block;
    color: $colorBlack !important;
    position: relative;
    text-decoration: none !important;
    cursor: pointer;
    @include hp_transition();
    @include respons-md {
      margin: 40px 9px;
      font-size: 14px;
    }
    @include respons-sm {
      margin: 30px 7px;
      font-size: 12px;
    }
    @include respons-xs {
      display: block;
      font-size: 18px;
      margin: 8px 0;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      height: 0;
      background-image: url("../img/pattern_peach.png");
      background-size: 8px;
      background-position: center center;
      @include transition(height 0.15s ease);
      @include respons-xs {
        display: none;
      }
    }

    &:hover {
      color: $colorPeach !important;
      &:after {
        height: 5px;
      }
    }

    &.active {
      color: $colorBlack !important;

      &:after {
        background-image: url("../img/pattern.png");
        height: 5px;
      }
      @include respons-xs {
        color: $colorPeach !important;
      }
    }
  }

  &__search {
    display: inline-block;
    margin-left: 14px;

    #searchForm input[type='submit'] {
      display: none;
    }

    @include respons-sm {
      margin-left: 4px;
    }

    input {
      display: inline-block;
      height: 20px;
      line-height: 20px;
      border: 0;
      border-bottom: 1px solid #ccc;
      outline: 0;
      width: 120px;
      @include respons-md {
        width: 100px;
      }
      @include respons-sm {
        width: 60px;
        font-size: 12px;
      }
      @include respons-xs {
        width: 120px;
        font-size: 14px;
      }
    }

    button {
      position: relative;
      vertical-align: middle;
      height: 20px;
      width: 20px;
      background: none;
      border: 0;
      outline: 0;
      display: inline-block;
      padding: 0;
      margin-right: 10px;
      @include respons-sm {
        width: 16px;
        height: 16px;
        margin-right: 7px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        @include respons-sm {
          width: 16px;
        }
      }
    }
  }
}

#menuToggle {
  position: absolute;
  top: 22px;
  right: 22px;
  width: 16px;
  height: 16px;
  background-image: url("../img/menu-toggle.png");
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;
  display: none;
  @include hp_transition();
  @include respons-xs {
    display: block;
  }

  &.open {
    background-image: url("../img/menu-close.png");
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  @include respons-xs {
    & > .nav__item { margin: 0; }
    position: relative;
    display: block;
    width: 100%;
  }
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 50;
  transition: max-height 1s ease-in-out;
  @include respons-xs {
    margin: 0;
    display: block;
    position: relative;
    box-shadow: none;
    max-height: 0;
    overflow: hidden;
  }
}

/* Links inside the dropdown */
.dropdown-content a {
  color: $colorBlack;
  text-decoration: none;
  display: block;
  margin: 15px 10px;
  white-space: nowrap;

  @include respons-xs {
    color: #7d7d7d !important;
    margin: 8px 0;
    white-space: normal;
    font-size: 14px;
    line-height: 18px;
    &:last-of-type {
      margin-bottom: 4px;
    }
  }
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  transition: max-height 1s ease-in-out;
  @include respons-xs {
    max-height: 1000px;
  }
}

