.contact {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    background-image: url("../img/pattern.png");
    background-size: 8px;
    display: block;
    z-index: -5;
    opacity: 0.15;
    @include respons-xs {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    background-image: url("../img/gradient.png");
    background-repeat: repeat-y;
    background-position: top left;
    background-size: 585px;
    display: block;
    z-index: -4;
    @include respons-xs {
      display: none;
    }
  }

  &__half {
    position: relative;
    overflow: hidden;
    @include respons-xs {
      border-top: 1px solid #e8e8e8;
    }

    .contact__title {
      opacity: 0;
      @include respons-xs {
        opacity: 1;
      }
    }
  }

  &__address {
    h3 {
      font-weight: 900;
      font-size: 24px;
      margin: 0 0 15px;
      @include respons-md {
        font-size: 22px;
      }
      @include respons-sm {
        font-size: 20px;
      }
      @include respons-xs {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      margin: 0;
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
      }
    }

    a {
      font-size: 16px;
      color: $colorBlack !important;
      text-decoration: none !important;
      @include hp_transition();
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
      }

      &:hover {
        color: $colorPeach !important;
      }
    }
  }

  &__button {
    padding: 0 18px;
    color: $colorWhite !important;
    text-decoration: none !important;
    margin: 40px auto 40px;
    position: relative;
    display: inline-block;
    line-height: 42px;
    background: $colorPeach;
    font-size: 16px;
    text-align: center;
    @include hp_transition();
    @include respons-md {
      padding: 0 12px;
      line-height: 36px;
      margin: 30px auto 40px;
      font-size: 15px;
    }
    @include respons-sm {
      padding: 0 10px;
      line-height: 28px;
      margin: 25px auto 30px;
      font-size: 14px;
    }
    @include respons-xs {
      padding: 0 12px;
      line-height: 36px;
      font-size: 15px;
    }

    &:hover {
      background: $colorBlack;
      color: $colorWhite !important;
    }
  }

  &__image {
    margin: 0 -15px;

    img {
      margin: 0 0 2rem 0;
      width: auto;
      height: 200px;
    }
  }

  &__title {
    margin: 0 -15px;
    @include respons-xs {
      margin-top: 25px;
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
      margin: 25px 0 35px;
      position: relative;
      left: 50%;
      @include respons-md {
        font-size: 22px;
      }
      @include respons-sm {
        font-size: 20px;
      }
      @include respons-xs {
        position: static;
        left: 0;
      }
    }

    &.static {
      h2 {
        position: static;
        left: 0;
      }

      .contact__icon {
        left: 0;
      }
    }
  }

  &__info {
    margin-top: 20px;
    margin-bottom: 40px;
    @include respons-md {}
    @include respons-sm {}
    @include respons-xs {
      margin-top: 20px;
      padding-top: 30px;
      border-top: 1px solid #e8e8e8;
    }

    .col-sm-6 {
      padding-top: 20px;
    }

    h3 {
      font-weight: 900;
      font-size: 16px;
      margin: 0 0 10px;
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
      }
    }

    p {
      font-size: 16px;
      margin: 0;
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
      }
    }

    a {
      font-size: 16px;
      color: $colorBlack !important;
      text-decoration: none !important;
      @include hp_transition();
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
      }

      &:hover {
        color: $colorPeach !important;
      }
    }
  }

  &__icon {
    margin: 2rem auto 0 auto;
    position: relative;
    left: 50%;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    @include respons-md {
      width: 85px;
      height: 85px;
      line-height: 85px;
    }
    @include respons-sm {
      width: 70px;
      height: 70px;
      line-height: 70px;
    }
    @include respons-xs {
      width: 70px;
      height: 70px;
      line-height: 70px;
      left: 0;
    }

    img {
      width: 40px;
      @include respons-md {
        width: 35px;
      }
      @include respons-sm {
        width: 25px;
      }
      @include respons-xs {
        width: 25px;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      top: 6px;
      left: -2px;
      z-index: -2;
      border-radius: 50%;
      background: url("../img/pattern_peach.png");
      background-size: 8px;
      background-position: center center;
      @include respons-md {
        width: 85px;
        height: 85px;
      }
      @include respons-sm {
        width: 70px;
        height: 70px;
        top: 5px;
        left: -1px;
      }
      @include respons-xs {
        width: 70px;
        height: 70px;
        top: 5px;
        left: -1px;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 50%;
      background: $colorWhite;
      border: 4px solid $colorPeach;
      @include respons-md {
        width: 85px;
        height: 85px;
      }
      @include respons-sm {
        width: 70px;
        height: 70px;
      }
      @include respons-xs {
        width: 70px;
        height: 70px;
      }
    }
  }

  &__person {
    display: block;
    margin: 0 20px 30px;
    vertical-align: top;
    @include respons-md {
      margin: 0 15px 25px;
    }
    @include respons-sm {
      margin: 0 12px 25px;
    }
    @include respons-xs {}

    img {
      width: 150px;
      margin: 0;
      border-radius: 50%;
      filter: grayscale(100%);
      @include respons-md {
        width: 120px;
      }
      @include respons-sm {
        width: 100px;
      }
      @include respons-xs {
        width: 120px;
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 900;
      margin: 15px 0 3px;
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
      }
    }
    p {
      text-transform: none;
    }
    a {
      font-size: 16px;
      color: $colorBlack !important;
      text-decoration: none !important;
      display: block;
      @include hp_transition();
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
      }

      &:hover {
        color: $colorPeach !important;
      }
    }
  }

  &__form {
    @include respons-xs {
      .col-sm-4 {
        text-align: center !important;
      }
    }

    input {
      padding: 0.7rem;
      width: 240px;
      max-width: 100%;
      background: #e8e8e8;
      line-height: 32px;
      height: 32px;
      border: 0;
      box-shadow: none;
      margin-bottom: 40px;
    }

    textarea {
      padding: 0.7rem;
      width: 100%;
      display: block;
      background: #e8e8e8;
      line-height: 22px;
      min-height: 160px;
      border: 0;
      box-shadow: none;
    }

    button {
      margin-left: auto;
      margin-right: auto;
      border: 0;
      display: block;
      width: 160px;
      @include respons-md {
        width: 140px;
      }
      @include respons-sm {
        width: 120px;
      }
      @include respons-xs {}
    }
  }
}

.grecaptcha-badge {
  margin: 40px auto 0 auto;
  text-align: center;
  display: none;
}

.form__sent {
  text-align: center;
}