.work {
  margin: 80px -15px 20px;
  @include respons-md {
    margin-bottom: 20px;
  }
  @include respons-sm {
    margin-bottom: 15px;
  }

  &__more {
    padding: 0 18px;
    color: $colorWhite !important;
    text-decoration: none !important;
    margin: 50px auto 70px;
    position: relative;
    display: inline-block;
    line-height: 42px;
    background: $colorPeach;
    font-size: 16px;
    text-align: center;
    @include hp_transition();
    @include respons-md {
      padding: 0 12px;
      line-height: 36px;
      margin: 40px auto 60px;
      font-size: 15px;
    }
    @include respons-sm {
      padding: 0 10px;
      line-height: 28px;
      margin: 30px auto 50px;
      font-size: 14px;
    }
    @include respons-xs {
      padding: 0 12px;
      line-height: 36px;
      font-size: 15px;
    }

    &.no-marg {
      margin: 0 !important;
    }

    &:hover {
      background: $colorBlack;
      color: $colorWhite !important;
    }
  }

  &__map {
    height: 400px;
    width: 100%;
    margin: 80px 0 40px;
    @include respons-sm {
      height: 300px;
    }
  }

  .photo__container {
    display: block;
  }

  &__photo {
    margin: 0 auto;
    width: auto;
    height: 500px;

    @include respons-md {
      height: 400px;
    }
    @include respons-sm {
      height: 350px;
    }
    @include respons-xs {
      height: 250px;
    }
  }

  &__text {
    h2 {
      font-size: 24px;
      font-weight: 900;
      color: $colorBlack;
      margin: 0 0 20px;
      @include respons-md {
        font-size: 22px;
        margin-bottom: 15px;
      }
      @include respons-sm {
        font-size: 20px;
        margin-bottom: 10px;
      }
      @include respons-xs {
        font-size: 22px;
        margin-bottom: 15px;
      }
    }

    p {
      font-size: 18px;
      color: $colorBlack;
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
      }
    }
  }
}
