.detail {
  &__slider {
    height: 530px;
    padding: 60px 25px;
    position: relative;
    z-index: 3;
    @include respons-md {
      height: 430px;
      padding: 40px 20px;
    }
    @include respons-sm {
      height: 360px;
      padding: 25px 15px;
    }
    @include respons-xs {
      margin-top: 360px;
      background: rgba(0,0,0,0.6);
    }

    h1, h2 {
      margin: 0 0 50px;
      font-size: 22px;
      color: $colorWhite;
      text-transform: uppercase;
      font-weight: 900;
      @include respons-md {
        font-size: 20px;
        margin-bottom: 35px;
      }
      @include respons-sm {
        font-size: 18px;
        margin-bottom: 25px;
      }
      @include respons-xs {
        font-size: 20px;
        margin-bottom: 35px;
      }
    }

    a {
      display: inline-block;
      padding: 0 15px;
      line-height: 42px;
      background: $colorPeach;
      color: $colorWhite !important;
      text-decoration: none !important;
      font-weight: 700;
      font-size: 16px;
      margin-top: 10px;
      @include hp_transition();
      @include respons-md {
        line-height: 36px;
        font-size: 15px;
        padding: 0 12px;
        margin-top: 8px;
      }
      @include respons-sm {
        line-height: 32px;
        font-size: 14px;
        padding: 0 9px;
        margin-top: 7px;
      }
      @include respons-xs {
        line-height: 36px;
        font-size: 15px;
        padding: 0 12px;
        margin: 15px auto 0;
        width: 200px;
        text-align: center;
        display: block;
      }

      &:hover {
        background: $colorBlack;
      }
    }

    .price {
      padding-left: 65px;
      background-image: url("../img/price.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 45px;
      margin-bottom: 30px;
      color: $colorWhite;
      @include respons-md {
        background-size: 36px;
        padding-left: 50px;
        margin-bottom: 25px;
      }
      @include respons-sm {
        background-size: 24px;
        padding-left: 36px;
        margin-bottom: 20px;
      }
      @include respons-xs {
        background-size: 36px;
        padding-left: 50px;
        margin-bottom: 25px;
      }

      &.recommended {
        background-image: url("../img/price_peach.png");
      }

      h3 {
        font-weight: 300;
        font-size: 16px;
        @include respons-md {
          font-size: 15px;
        }
        @include respons-sm {
          font-size: 14px;
        }
        @include respons-xs {
          font-size: 15px;
        }
      }

      h4 {
        font-weight: 900;
        font-size: 24px;
        @include respons-md {
          font-size: 22px;
        }
        @include respons-sm {
          font-size: 20px;
        }
        @include respons-xs {
          font-size: 22px;
        }
      }
    }
  }

  &__thumbs {
    margin: 70px 40px;
    @include respons-md {
      margin: 70px 25px;
    }
    @include respons-xs {
      margin: 70px 25px;
    }
    a {
      display: block;
      text-align: center;
      outline: 0;
      @include respons-xs {
        padding: 0 25px;
      }

      img {
        display: inline-block;
      }
    }
  }

  &__arrow {
    width: 19px;
    height: 38px;
    position: absolute;
    top: 50%;
    margin-top: -19px;
    background-image: url("../img/arrow.png");
    background-size: 38px;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 0.4;
    z-index: 3;
    @include hp_transition();

    &.prev {
      left: -20px;
      background-position: left center;
      @include respons-md {
        left: -25px;
      }
      @include respons-sm {}
      @include respons-xs {
        left: -15px;
      }
    }

    &.next {
      right: -20px;
      background-position: right center;
      @include respons-md {
        right: -25px;
      }
      @include respons-sm {}
      @include respons-xs {
        right: -15px;
      }
    }

    &:hover {
      background-image: url("../img/arrow_peach.png");
      opacity: 1;
    }
  }

  &__text {
    padding-left: 195px;
    margin-bottom: 50px;
    @include respons-md {
      padding-left: 165px;
    }
    @include respons-sm {
      padding-left: 100px;
    }
    @include respons-xs {
      padding-top: 195px;
      padding-left: 0;
    }

    @media print {
      padding-top: 20px;
      padding-left: 0 !important;
    }

    h2 {
      font-size: 24px;
      font-weight: 900;
      color: $colorBlack;
      margin: 0 0 20px;
      @include respons-md {
        font-size: 22px;
        margin-bottom: 15px;
      }
      @include respons-sm {
        font-size: 20px;
        margin-bottom: 10px;
      }
      @include respons-xs {
        font-size: 22px;
        margin-bottom: 15px;
      }
    }

    p {
      font-size: 16px;
      color: $colorBlack;
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
      }
    }
  }

  &__table {
    @include respons-xs {
      margin-bottom: 30px;
    }

    h2 {
      font-size: 24px;
      font-weight: 900;
      color: $colorBlack;
      margin: 0 0 20px;
      @include respons-md {
        font-size: 22px;
        margin-bottom: 15px;
      }
      @include respons-sm {
        font-size: 20px;
        margin-bottom: 10px;
      }
      @include respons-xs {
        font-size: 22px;
        margin-bottom: 15px;
      }
    }

    table {
      tr {
        @include hp_transition();

        &:nth-of-type(odd) {
          background: #eee;
        }

        &:hover {
          background: #ffe1d9;
        }

        td {
          border: 0;
          padding: 10px 15px;
          font-size: 16px;
          @include respons-md {
            font-size: 15px;
            padding: 8px 10px;
          }
          @include respons-sm {
            font-size: 14px;
            padding: 6px 8px;
          }
          @include respons-xs {
          }

          @media screen and (max-width: 467px) {
            font-size: 12px;
          }

          &:last-of-type {
            text-align: left;
          }
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 15px;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    border-radius: 50%;
    @include respons-md {
      width: 120px;
      height: 120px;
      line-height: 120px;
    }
    @include respons-sm {
      width: 75px;
      height: 75px;
      line-height: 75px;
    }
    @include respons-xs {
      width: 150px;
      height: 150px;
      line-height: 150px;
      left: 50%;
      margin-left: -75px;
    }

    img {
      width: 60px;
      @include respons-md {
        width: 48px;
      }
      @include respons-sm {
        width: 30px;
      }
      @include respons-xs {
        width: 60px;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 150px;
      height: 150px;
      top: 10px;
      left: -2px;
      z-index: -2;
      border-radius: 50%;
      background: url("../img/pattern_peach.png");
      background-size: 8px;
      background-position: center center;
      @include respons-md {
        width: 120px;
        height: 120px;
      }
      @include respons-sm {
        width: 75px;
        height: 75px;
        top: 5px;
        left: -1px;
      }
      @include respons-xs {
        width: 150px;
        height: 150px;
        top: 10px;
        left: -2px;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 150px;
      height: 150px;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 50%;
      background: $colorWhite;
      border: 4px solid $colorPeach;
      @include respons-md {
        width: 120px;
        height: 120px;
      }
      @include respons-sm {
        width: 75px;
        height: 75px;
      }
      @include respons-xs {
        width: 150px;
        height: 150px;
      }
    }
  }

  &__projects {
    margin-bottom: 120px;
    @include respons-xs {
      margin-bottom: 0;
    }
  }


  &__plans {
    margin: 40px 25px 0 25px;
    @include respons-md {
      margin: 40px 25px 0 25px;
    }
    @include respons-xs {
      margin: 10px 25px 0 25px;
    }
    a {
      outline: 0;
      @include respons-xs {
        padding: 0 25px;
      }
      img {
        margin: 0 auto;
      }
    }
  }

  &__slide {

    float: none !important;
    display: inline-block !important;
    vertical-align: middle !important;
  }
}

.print-only {
  display: none !important;
  @media print {
    display: inherit !important;
  }
}

.hidden-print {
  @media print {
    display: none !important;
  }
}

.print-margin-sides-none {
  @media print {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.print-margin-sides-15 {
  @media print {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

.print-margin-top-5rem {
  @media print {
    margin-top: 5rem;
  }
}

.print-next-page-before {
  @media print {
    float: none;
    display: block !important;
    page-break-before: always !important;
  }
}

.product-info {
  table {
    .photo {
      width: 50%;
    }
    .pricing {
      padding: 0 0 0 3rem;
    }
  }
}

.detail__image-print {
  text-align: center;
  img {
    width: 70%;
  }
}

table.print-only-table-styles tr {
  @media print {
    border-bottom: 1px solid lightgray;
    td:last-of-type {
      text-align: right;
    }
  }
}