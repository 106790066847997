.row.standards {
  .standards-icon {
    margin: 0 auto;
    position: relative;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      top: 6px;
      left: -2px;
      z-index: -2;
      border-radius: 50%;
      background: url(../img/pattern_peach.png);
      background-size: 8px;
      background-position: center center;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 50%;
      background: #FFFFFF;
      border: 4px solid #FF8769;
    }

    img {
      width: 40px;
    }
  }

  .standards-links, .standards-tabs {
    .tab-heading {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a, h4 {
      font-size: 20px;
      @media (max-width: 991px) {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 18px;
        text-align: center;
        display: block;
        width: fit-content;
        margin: 0 auto;
      }

      font-weight: 900;
      color: #000000 !important;
      text-decoration: none !important;
      padding: 0;
      margin: 5px 20px;
      display: inline-block;
      border-bottom: 1px solid transparent;
      transition: all 0.4s ease;
      cursor: pointer;

      &:before {
        content: " ";
        display: block;
        position: absolute;
        opacity: 0.2;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        background-image: url(../img/pattern.png);
        background-size: 8px;
        background-position: center center;
        transition: height 0.15s ease;
        height: 100%;
        width: 92.4%;
        margin: 0 auto;
      }

      &.active {
        color: #FF8769 !important;
        border-bottom-color: #FF8769;
        &:before {
          height: 0;
        }
      }

      &:hover {
        color: #FF8769 !important;
        &:before {
          background-image: url(../img/pattern_peach.png);
          height: 100%;
        }
      }
    }

    h4 {
      margin: 1rem auto;
      display: block;
      text-align: center;
      &:before {
        width: 96%;
      }
    }
  }

  .standards-tabs {
    .tab-pane {
      .tab-heading {
        display: none;
        opacity: 0;
      }
      @media (max-width: 991px) {
        display: block;
        opacity: 1;
        .tab-heading {
          display: block;
          opacity: 1;
        }
      }
    }
  }

  table {
    table-layout: auto;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    tr {
    }
    thead tr {
      background-color: #ffe1d9;
      font-weight: 600;
    }
    td {
      padding: 0.3rem 0.5rem;
      &:first-child {
        width: 30%;
        @media (max-width: 767px) {
          width: 50%;
        }
      }
    }
  }
}