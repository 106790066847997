.testimonials {
  &__title {
    font-size: 24px;
    color: $colorBlack;
    margin: 100px 0 40px;
    font-weight: 900;
    @include respons-md {
      font-size: 22px;
    }
    @include respons-sm {
      font-size: 20px;
    }
    @include respons-xs {
      font-size: 24px;
    }
  }

  &__item {
    //min-height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 20px;
      background: $colorWhite;
      display: block;
      content: "";
    }

    h3 {
      margin-top: 100px;
      margin-bottom: 20px;
      font-weight: 900;
      @include respons-md {
        font-size: 22px;
        margin-top: 70px;
      }
      @include respons-sm {
        font-size: 20px;
        margin-top: 50px;
        margin-bottom: 10px;
      }
      @include respons-xs {
      }
    }

    .description {
      max-width: 380px;
      font-size: 16px;
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        max-width: 310px;
        font-size: 14px;
      }
      @include respons-xs {
      }
    }
  }

  &__arrow {
    width: 19px;
    height: 38px;
    position: absolute;
    top: 50%;
    margin-top: -19px;
    background-image: url("../img/arrow.png");
    background-size: 38px;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 3;
    opacity: 0.4;
    @include hp_transition();

    &.prev {
      left: 50%;
      margin-left: -550px;
      background-position: left center;
      @include respons-md {
        margin-left: -450px;
      }
      @include respons-sm {
        margin-left: -360px;
      }
      @include respons-xs {
        margin-left: 0;
        left: 10px;
      }
    }

    &.next {
      right: 50%;
      margin-right: -550px;
      background-position: right center;
      @include respons-md {
        margin-right: -450px;
      }
      @include respons-sm {
        margin-right: -360px;
      }
      @include respons-xs {
        margin-right: 0;
        right: 10px;
      }
    }

    &:hover {
      background-image: url("../img/arrow_peach.png");
      opacity: 1;
    }
  }
}
