.about {
  &__links {
    margin-bottom: 40px;
    @include respons-xs {
      text-align: center;
    }

    a {
      font-size: 22px;
      font-weight: 900;
      color: $colorBlack !important;
      text-decoration: none !important;
      padding: 0 0 6px;
      margin: 0 20px;
      display: inline-block;
      border-bottom: 1px solid transparent;
      @include hp_transition();
      @include respons-md {
        font-size: 20px;
        padding: 0 6px 4px;
        margin: 0 12px;
      }
      @include respons-sm {
        font-size: 18px;
        padding: 0 4px 2px;
        margin: 0 8px;
      }
      @include respons-xs {
        margin-bottom: 10px;
        padding: 0 6px 4px;
      }

      &:hover {
        color: $colorPeach !important;
      }

      &.active {
        color: $colorPeach !important;
        border-bottom-color: $colorPeach;
      }
    }
  }

  h1 {
    font-size: 28px;
    color: $colorPeach;
    font-weight: 900;
    margin: 40px 0;
    @include respons-md {
      font-size: 26px;
    }
    @include respons-sm {
      font-size: 24px;
    }
  }

  h2 {
    font-size: 24px;
    color: $colorBlack;
    margin: 40px 0 20px;
    @include respons-md {
      font-size: 22px;
    }
    @include respons-sm {
      font-size: 20px;
    }
  }

  img {
    margin: 0 0 30px;
  }

  table {
    table-layout: fixed;
    td {
      padding: 3px 6px;
      &:first-of-type {
        white-space: nowrap;
        width: 25%;
      }
    }
  }

  ul {
    li {
      margin-bottom: 10px;
    }
  }
}

ul.nav-tabs > li > a, .nav-tabs > li * {
 border: 0 !important;
}

ul.nav-tabs > li > a {
  color: grey !important;
  font-size: 20px;
  font-weight: bold;
}

ul.nav-tabs > li.active > a {
 color: $colorPeach !important;
}

ul.nav-tabs > li > a, .nav-tabs > li > a {
  background: url(../img/pattern_gray-transparent.png);
  background-size: 8px;
  border-radius: 0;
}

ul.nav-tabs > li.active > a, .nav-tabs > li.active > a {
  background: none !important;
  border-bottom: 1px solid $colorPeach !important;
  color: $colorPeach !important;
  -webkit-filter: none !important; /* Safari 6.0 - 9.0 */
  filter: none !important;
}

.row.about__text {
  @media (max-width: 767px) {
    padding-top: 0 !important;
  }
}

ul.nav.nav-tabs.nav-justified {
  @media (max-width: 767px) {
    opacity: 1;
    position: relative;
    display: block;
    left: initial;
    margin-bottom: 10rem;
    box-shadow: none;
  }
}

.about__text .list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about__text img.list-icon {
  margin: 0;
  width: 50px;
  float: left;
  position: absolute;
}

.about__text .list-text {
  font-weight: bolder;
  font-size: 18px;
  margin-left: 6rem;
  height: 9rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.tab-content .tab-pane {
  .about__icon {
    top: 5rem;
  }
  .about__text {
    margin-top: 5rem;
  }
}

.about__icon {
  position: absolute;
  left: 15px;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border-radius: 50%;
  @include respons-md {
    width: 120px;
    height: 120px;
    line-height: 120px;
  }
  @include respons-sm {
    width: 75px;
    height: 75px;
    line-height: 75px;
  }
  @include respons-xs {
    width: 150px;
    height: 150px;
    line-height: 150px;
    left: 50%;
    margin-left: -75px;
  }

  img {
    width: 80px;
    margin: 0;
    @include respons-md {
      width: 60px;
    }
    @include respons-sm {
      width: 45px;
    }
    @include respons-xs {
      width: 70px;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 150px;
    height: 150px;
    top: 10px;
    left: -2px;
    z-index: -2;
    border-radius: 50%;
    background: url("../img/pattern_peach.png");
    background-size: 8px;
    background-position: center center;
    @include respons-md {
      width: 120px;
      height: 120px;
    }
    @include respons-sm {
      width: 75px;
      height: 75px;
      top: 5px;
      left: -1px;
    }
    @include respons-xs {
      width: 150px;
      height: 150px;
      top: 10px;
      left: -2px;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 50%;
    background: $colorWhite;
    border: 4px solid $colorPeach;
    @include respons-md {
      width: 120px;
      height: 120px;
    }
    @include respons-sm {
      width: 75px;
      height: 75px;
    }
    @include respons-xs {
      width: 150px;
      height: 150px;
    }
  }
}

.icon--centered {
  text-align: center;
  position: relative;
  margin: 0 auto;
  left: 0;
}

.about__text {
  text-align: left;
  padding-left: 195px;
  margin-bottom: 50px;
  @include respons-md {
    padding-left: 165px;
  }
  @include respons-sm {
    padding-left: 100px;
  }
  @include respons-xs {
    padding-top: 195px;
    padding-left: 0;
  }

  @media print {
    padding-top: 20px;
    padding-left: 0 !important;
  }

  h2 {
    font-size: 24px;
    font-weight: 900;
    color: $colorBlack;
    margin: 0 0 20px;
    @include respons-md {
      font-size: 22px;
      margin-bottom: 15px;
    }
    @include respons-sm {
      font-size: 20px;
      margin-bottom: 10px;
    }
    @include respons-xs {
      font-size: 22px;
      margin-bottom: 15px;
    }
  }

  p {
    font-size: 16px;
    color: $colorBlack;
    @include respons-md {
      font-size: 15px;
    }
    @include respons-sm {
      font-size: 14px;
    }
    @include respons-xs {
      font-size: 15px;
    }
  }
}

.about__heading {
  font-size: 24px;
  font-weight: 900;
  color: $colorBlack;
  margin: 0 0 20px;
  @include respons-md {
    font-size: 22px;
    margin-bottom: 15px;
  }
  @include respons-sm {
    font-size: 20px;
    margin-bottom: 10px;
  }
  @include respons-xs {
    font-size: 22px;
    margin-bottom: 15px;
  }
}

.about__carousel {
  .panel.panel-default {
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .panel-heading {
    background-color: #ffe1d9 !important;
    border-radius: 0 !important;
    &.expanded {
      background-color: #fe8263 !important;
    }
  }
  .panel-title {
    text-align: left;
    a {
      font-weight: bolder;
      text-align: left;
      margin: 0;
      color: $colorBlack !important;
      padding: 0;
      font-size: 18px;
      border: none;
      i {
        margin-right: 0.3rem;
      }
      text-decoration: none !important;
    }
    a.collapsed .fa.fa-chevron-up {
      display: none;
    }
    a:not(.collapsed) .fa.fa-chevron-down {
      display: none;
    }
  }

  .panel-body {
    padding: 0;
    text-align: left;
    border: none !important;
  }
}

table.about__specs-table {
  width: 100%;
  td {
    padding: 7px 10px;
    &:first-child {
      border-right: 2px solid white;
    }
  }
  tr:nth-child(even) {
    background-color: #f1f1f1;
  }
}

.about a.about__download-pdf {
  font-weight: bold;
  font-size: 18px;
  display: block;
  text-align: center;
  color: $colorPeach;
  vertical-align: middle;
  margin-top: 2rem;

  img {
    width: 40px;
    background-color: $colorPeach;
    border-radius: 50%;
    margin: 0 1rem 0 0;
    padding: 0.6rem;
  }

  &:hover {
    color: $colorBlack;
    text-decoration: none;
  }
}

.spacer-bottom {
  margin-bottom: 15rem;
  @media (max-width: 767px) {
    margin-bottom: 5rem;
  }
}