@mixin respons-xs {
     @media (max-width: $screen-xs-max){
        @content;
    }
}
@mixin respons-sm {
     @media (max-width: $screen-sm-max){
        @content;
    }
}
@mixin respons-md {
     @media (max-width: $screen-md-max){
        @content;
    }
}
@mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}
@mixin hp_transition() {
     @include transition(all 0.4s ease);
}
@mixin slow_transition() {
     @include transition(all 1s ease);
}
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
     @if $inset{
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    }
    @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}
@mixin appearance($value) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    appearance: $value;
}
@mixin filter($filter-type,$filter-amount) {
    -webkit-filter: $filter-type+unquote( '(#{$filter-amount})');
    -moz-filter: $filter-type+unquote( '(#{$filter-amount})');
    -ms-filter: $filter-type+unquote( '(#{$filter-amount})');
    -o-filter: $filter-type+unquote( '(#{$filter-amount})');
    filter: $filter-type+unquote( '(#{$filter-amount})');
}
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
@mixin placeholder {
    ::-webkit-input-placeholder {
        @content
    }

    :-moz-placeholder {
        @content
    }

    ::-moz-placeholder {
        @content
    }

    :-ms-input-placeholder {
        @content
    }
}
