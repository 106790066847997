/**
    Privacy page styling
 */
.privacy {
  margin-bottom: 80px;

  h3 {
    font-size: 20px;
  }

  ul.roman-numerals {
    padding-left: 20px;
    @include respons-xs {
      padding-left: 30px;
    }

    li {
      font-size: 24px;
      list-style-type: upper-roman;
      &:before {
        display: none;
      }
    }
    h2 {
      padding-bottom: 5px !important;
    }
  }

  ul.decimal-numerals {
    margin-bottom: 30px;
    margin-left: 17px;
    @include respons-xs {
      padding-left: 0;
    }

    li {
      font-size: 16px;
      list-style-type: decimal;
    }
  }
}
