.partners {
  margin: 30px -15px 120px;
  padding: 0 60px;

  &__item {
    padding: 0 20px;
    outline: 0;
  }

  &__arrow {
    width: 19px;
    height: 38px;
    position: absolute;
    top: 50%;
    margin-top: -19px;
    background-image: url('../img/arrow.png');
    background-size: 38px;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 0.4;
    @include hp_transition();

    &.prev {
      left: 10px;
      background-position: left center;
    }

    &.next {
      right: 10px;
      background-position: right center;
    }

    &:hover {
      background-image: url('../img/arrow_peach.png');
      opacity: 1;
    }
  }
}

// slick vertical align fix
.partners__item.slick-slide {
  float: none;
  display: inline-block;
  vertical-align: middle;
}