.services {
  padding-top: 45px;
  padding-bottom: 45px;
  position: relative;

  hr {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    background: #ccc;
    height: 1px;
    margin: 0;

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }
  }

  &__item {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__text {
    padding-left: 195px;
    @include respons-md {
      padding-left: 165px;
    }
    @include respons-sm {
      padding-left: 100px;
    }
    @include respons-xs {
      padding-left: 0;
      padding-top: 195px;
    }

    h2 {
      font-size: 24px;
      font-weight: 900;
      color: $colorBlack;
      margin: 0 0 20px;
      @include respons-md {
        font-size: 20px;
        margin-bottom: 15px;
      }
      @include respons-sm {
        font-size: 18px;
        margin-bottom: 12px;
      }
      @include respons-xs {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      color: $colorBlack;
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 40px;
    left: 15px;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    border-radius: 50%;
    @include respons-md {
      width: 120px;
      height: 120px;
      line-height: 120px;
    }
    @include respons-sm {
      width: 75px;
      height: 75px;
      line-height: 75px;
    }
    @include respons-xs {
      top: 40px;
      left: 50%;
      margin-left: -75px;
      width: 150px;
      height: 150px;
      line-height: 150px;
    }

    img {
      width: 60px;
      @include respons-md {
        width: 48px;
      }
      @include respons-sm {
        width: 30px;
      }
      @include respons-xs {
        width: 60px;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 150px;
      height: 150px;
      top: 10px;
      left: -2px;
      z-index: -2;
      border-radius: 50%;
      background: url("../img/pattern_peach.png");
      background-size: 8px;
      background-position: center center;
      @include respons-md {
        width: 120px;
        height: 120px;
      }
      @include respons-sm {
        width: 75px;
        height: 75px;
        top: 5px;
        left: -1px;
      }
      @include respons-xs {
        width: 150px;
        height: 150px;
        top: 10px;
        left: -2px;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 150px;
      height: 150px;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 50%;
      background: $colorWhite;
      border: 4px solid $colorPeach;
      @include respons-md {
        width: 120px;
        height: 120px;
      }
      @include respons-sm {
        width: 75px;
        height: 75px;
      }
      @include respons-xs {
        width: 150px;
        height: 150px;
      }
    }
  }
}
