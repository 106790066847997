body {
    font-family: $fontBody;
    color: $colorBlack;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $fontHeadings;
    font-weight: 700;
}
