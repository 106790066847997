.footer {
  &__top {

    @media print {
      page-break-before: always !important;
    }

    height: 380px;
    background: #ebebeb;
    @include respons-md {
      height: 350px;
    }
    @include respons-sm {
      height: 290px;
    }
    @include respons-xs {
      height: auto;
      div.col-xs-12 {
        text-align: center !important;
      }
    }

    h2 {
      font-size: 24px;
      color: $colorBlack;
      margin: 60px 0 50px;
      @include respons-md {
        font-size: 22px;
      }
      @include respons-sm {
        font-size: 20px;
        margin: 40px 0 30px;
      }
      @include respons-xs {
        font-size: 22px;
      }
    }

    h3, h3 a {
      font-size: 24px;
      color: $colorBlack;
      margin: 0 0 30px;
      font-weight: 900;
      @include respons-md {
        font-size: 22px;
        margin-bottom: 25px;
      }
      @include respons-sm {
        font-size: 20px;
        margin-bottom: 12px;
      }
      @include respons-xs {
        font-size: 24px;
        margin: 0 0 30px;
        text-align: center;
      }
    }

    h4 {
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      @include respons-xs {
        text-align: center;
      }
    }

    p {
      font-size: 16px;
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
        text-align: center;
        margin-bottom: 30px;
      }
    }

    a {
      font-size: 16px;
      color: $colorBlack !important;
      text-decoration: none !important;
      @include hp_transition();
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {
        font-size: 15px;
        text-align: center;
      }

      &:hover {
        color: $colorPeach !important;
      }
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    border-radius: 50%;
    @include respons-md {
      width: 120px;
      height: 120px;
      line-height: 120px;
    }
    @include respons-sm {
      width: 75px;
      height: 75px;
      line-height: 75px;
    }
    @include respons-xs {
      width: 150px;
      height: 150px;
      line-height: 150px;
      margin-bottom: 40px;
    }

    @media print {
      display: none !important;
    }

    img {
      width: 60px;
      z-index: 2;
      position: relative;
      @include respons-md {
        width: 48px;
      }
      @include respons-sm {
        width: 30px;
      }
      @include respons-xs {
        width: 60px;
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 150px;
      height: 150px;
      top: 10px;
      left: -2px;
      z-index: 0;
      border-radius: 50%;
      background: url("../img/pattern_peach.png");
      background-size: 8px;
      background-position: center center;
      @include respons-md {
        width: 120px;
        height: 120px;
      }
      @include respons-sm {
        width: 75px;
        height: 75px;
        top: 5px;
        left: -1px;
      }
      @include respons-xs {
        width: 150px;
        height: 150px;
        top: 10px;
        left: -2px;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 150px;
      height: 150px;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: 50%;
      background: #ebebeb;
      border: 4px solid $colorPeach;
      @include respons-md {
        width: 120px;
        height: 120px;
      }
      @include respons-sm {
        width: 75px;
        height: 75px;
      }
      @include respons-xs {
        width: 150px;
        height: 150px;
      }
    }
  }

  &__bottom {
    height: auto;
    background: #d6d6d6;
    padding: 20px 0;
    @include respons-md {}
    @include respons-sm {}
    @include respons-xs {}

    @media print {
      display: none !important;
    }
  }

  &__logo {
    img {
      width: 100px;
    }
  }

  &__menu {
    margin: 15px 0;

    a {
      display: inline-block;
      margin: 0 4px;
      color: $colorBlack !important;
      text-decoration: none !important;
      @include hp_transition();

      &:hover {
        color: $colorPeach !important;
      }
      @include respons-xs {
        display: block;
        margin: 4px;
      }
    }
  }

  &__social {
    a {
      opacity: 0.5;
      @include hp_transition();

      img {
        height: 20px;
      }

      &:hover {
        opacity: 0.85;
      }
    }
  }
}
