.content {
  &__page {
    margin: 80px -15px 140px;
    @include respons-md {
      margin: 70px -15px 120px;
    }
    @include respons-sm {
      margin: 50px -15px 100px;
    }
    @include respons-xs {}

    p {
      font-size: 16px;
      @include respons-md {
        font-size: 15px;
      }
      @include respons-sm {
        font-size: 14px;
      }
      @include respons-xs {}
    }

    img {
      margin: 30px 0;
    }
  }
}
