.projects {
  &__page {
    margin: 50px -15px 80px;
    @include respons-md {
      margin-bottom: 20px;
    }
    @include respons-sm {
      margin-bottom: 15px;
    }
  }

  &__title h2 {
    font-size: 24px;
    color: $colorBlack;
    margin: 70px 0 30px;
    font-weight: 900;
    @include respons-md {
      font-size: 22px;
    }
    @include respons-sm {
      font-size: 20px;
    }
    @include respons-xs {
      font-size: 24px;
    }
  }

  &__photo {
    margin-top: 80px;
    display: inline-block;

    img {
      margin: 0 auto;
      width: auto;
      height: 500px;
      @include respons-md {
        height: 400px;
      }
      @include respons-sm {
        height: 350px;
      }
      @include respons-xs {
        height: 250px;
      }
      @media screen and (max-width: 479px) {
        width: auto;
        height: auto;
      }
    }
  }

  &__button {
    margin: 40px 0 80px;
    background: $colorPeach;
    color: $colorWhite !important;
    line-height: 42px;
    padding: 0 17px;
    font-size: 16px;
    font-weight: 300;
    display: inline-block;
    text-decoration: none !important;
    @include hp_transition();
    @include respons-md {
      font-size: 15px;
      line-height: 36px;
      padding: 0 15px;
    }
    @include respons-sm {
      font-size: 14px;
      line-height: 32px;
      padding: 0 12px;
    }
    @include respons-xs {
      font-size: 15px;
      line-height: 36px;
      padding: 0 15px;
    }

    &:hover {
      background: $colorBlack;
    }
  }

  &__cover {
    margin: 12px -15px 0;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;
    @include hp_transition();
    @include respons-md {
      height: 250px;
    }
    @include respons-sm {
      height: 170px;
    }
    @include respons-xs {
      margin: 12px 0 0;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $colorPeach;
      z-index: 0;
      opacity: 0;
      @include hp_transition();
    }

    h3 {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 18px 15px;
      background: rgba(255,255,255,0.6);
      color: $colorBlack;
      font-size: 24px;
      font-weight: 900;
      margin: 0;
      z-index: 1;
      height: 8.7rem;
      display: flex;
      align-items: flex-end;
      @include respons-md {
        height: 8rem;
        font-size: 22px;
        padding: 15px 15px;
      }
      @include respons-sm {
        height: 6.5rem;
        font-size: 17px;
        padding: 12px 15px;
      }
      @include respons-xs {}

      a {
        color: $colorBlack;
        &:hover {
          color: $colorPeach;
          text-decoration: none;
        }
      }
    }
  }

  &__item {
    padding-bottom: 30px;
    position: relative;
    margin-bottom: 12px;
    border-right: 1px solid $colorWhite;
    @include hp_transition();

    &:nth-of-type(3n) {
      border-right: 0;
    }

    &:nth-of-type(4n) {
      clear: left;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 20px;
      background-image: url("../img/pattern.png");
      background-size: 8px;
      background-position: center center;
      @include hp_transition();
      @include respons-xs {
        left: 15px;
        right: 15px;
      }
    }

    .projects__year {
      font-weight: 700;
      margin: 25px 0 0 0;
      @include respons-xs {
        padding: 0 15px;
      }
    }

    p {
      margin: 20px 0 10px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 300;
      height: 9rem;
      @include hp_transition();
      @include respons-md {
        font-size: 14px;
        margin-top: 20px;
      }
      @include respons-sm {
        font-size: 12px;
        margin-top: 15px;
        line-height: 18px;
      }
      @include respons-xs {
        height: auto;
        font-size: 15px;
        line-height: 20px;
        padding: 0 15px;
      }
    }

    &:hover {
      margin-bottom: 0;

      &:after {
        background-image: url("../img/pattern_peach.png");
      }

      p {
        margin-bottom: 22px;
      }

      .projects__cover {
        margin-top: 0;
        height: 260px;
        @include respons-md {
          height: 260px;
        }
        @include respons-sm {
          height: 180px;
        }
        @include respons-xs {}

        &:before {
          opacity: 0.5;
        }
      }
    }
  }

  &__more {
    position: relative;
    display: block;
    text-align: center;
    padding-top: 25px;
    color: $colorPeach !important;
    text-decoration: none !important;
    @include hp_transition();

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 25px;
      height: 25px;
      top: 0;
      left: 50%;
      margin-left: -19px;
      width: 38px;
      height: 19px;
      background-image: url("../img/arrow_peach.png");
      background-size: 38px;
      background-position: bottom center;
      background-repeat: no-repeat;
      @include hp_transition();
    }

    &:hover {
      color: $colorBlack !important;

      &:before {
        background-image: url("../img/arrow.png");
      }
    }
  }

  &__map {
    height: 400px;
    width: 100%;
    margin: 40px 0;
    @include respons-sm {
      height: 300px;
    }
  }
}
