.page {
  &__header {
    h1, p {
      background-image: url("../img/title.png");
      background-repeat: no-repeat;
      background-position: 40% center;
      background-size: cover;
      min-height: 120px;
      margin: 45px 0 35px;
      min-width: 600px;
      padding-left: 30px;
      padding-right: 45px;
      font-size: 54px;
      //line-height: 110px;
      text-align: left;
      font-weight: 900;
      color: $colorWhite;
      display: inline-block;
      line-height: 6rem;
      height: auto;
      padding: 3rem;

      @include respons-md {
        min-height: 90px;
        height: auto;
        line-height: 50px;
        margin: 35px 0 25px;
        font-size: 42px;
        min-width: 400px;
        padding-left: 30px;
      }
      @include respons-sm {
        min-height: 60px;
        height: auto;
        line-height: 25px;
        margin: 30px 0 20px;
        font-size: 27px;
        min-width: 300px;
      }
      @include respons-xs {
        min-height: 60px;
        height: auto;
        line-height: 25px;
        margin: 30px 0 20px;
        font-size: 20px;
        min-width: 270px;
      }
    }

    @media print {
      background: none !important;
      .row > div {
        text-align: left !important;
        h1 {
          background: none !important;
          padding-left: 0 !important;
        }
      }
    }
  }
}
