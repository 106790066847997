.slider-text .slider__item:before {
  background: none !important;
}

.slick-slider {
  &:hover {
    .slider__arrow.hover-only {
      opacity: 1;
    }
  }
  .slider__arrow.hover-only {
    opacity: 0;
  }
}


.slider, .slider-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-track {
    padding-bottom: 30px;
    @include respons-xs {
      padding-bottom: 0;
    }
  }

  &__wrapper {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 30px;
      right: 0;
      margin-left: 195px;
      background: $colorBlack;
      opacity: 0.6;
      z-index: 2;
      @include respons-md {
        margin-left: 166px;
      }
      @include respons-sm {
        margin-left: 60px;
      }
      @include respons-xs {
        display: none;
      }
    }
  }

  &__item {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100%;
    @include respons-xs {
      background-position: bottom center;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: url("../img/pattern.png");
      display: block;
      background-size: 8px;
      opacity: 0.25;
    }

    h2 {
      a {
        color: $colorWhite;
        text-decoration: none;
        span {
          font-size: 28px;
          @include respons-md {
            font-size: 28px;
          }
          @include respons-sm {
            font-size: 20px;
          }
        }
      }
      background-image: url("../img/title.png");
      background-repeat: no-repeat;
      background-position: 40% center;
      background-size: cover;
      margin: 0;
      min-width: 600px;
      padding-left: 20px;
      padding-right: 45px;
      position: absolute;
      bottom: -30px;
      left: 50%;
      font-size: 54px;
      font-weight: 900;
      color: $colorWhite;
      margin-left: -570px;
      height: auto;
      line-height: 40px;
      padding-top: 22px;
      padding-bottom: 18px;

      @include respons-md {
        line-height: 36px;
        bottom: -25px;
        font-size: 42px;
        margin-left: -470px;
        min-width: 400px;
      }
      @include respons-sm {
        line-height: 28px;
        bottom: -15px;
        font-size: 32px;
        margin-left: -360px;
        min-width: 280px;
        padding-top: 17px;
      }
      @include respons-xs {
        //bottom: 50%;
        bottom: 71%;
        line-height: 26px;
        font-size: 30px;
        padding: 10px 15px;
        background: rgba(255, 135, 105, 0.9);
        background-image: none;
        min-width: none;
        margin-left: 0;
        left: 0;
        right: 0;
        height: auto;
        padding-top: 15px;
      }
    }
  }

  &__arrow {
    width: 19px;
    height: 38px;
    position: absolute;
    top: 50%;
    margin-top: -19px;
    background-image: url("../img/arrow.png");
    background-size: 38px;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 3;
    opacity: 0.6;
    @include hp_transition();
    @include respons-xs {
      display: none !important;
    }
    &.arrow--white {
      background-image: url("../img/arrow-white.png");
    }

    &.prev {
      left: 50%;
      margin-left: -550px;
      background-position: left center;
      @include respons-md {
        margin-left: -450px;
      }
      @include respons-sm {
        margin-left: -360px;
      }
      @include respons-xs {
        top: 25%;
        left: 25px;
        margin-left: 0;
      }
    }

    &.next {
      right: 50%;
      margin-right: -160px;
      background-position: right center;
      @include respons-md {
        margin-right: -140px;
      }
      @include respons-sm {
        margin-right: -35px;
      }
      @include respons-xs {
        top: 25%;
        margin-right: 0;
        right: 25px;
      }
    }

    &:hover {
      background-image: url("../img/arrow_peach.png");
      opacity: 1;
    }
  }

  &__form {
    height: 530px;
    padding: 60px 25px;
    position: relative;
    z-index: 3;
    @include respons-md {
      height: 430px;
      padding: 40px 20px;
    }
    @include respons-sm {
      height: 360px;
      padding: 25px 15px;
    }
    @include respons-xs {
      height: 340px;
      margin-top: 137px;
      background: rgba(0,0,0,0.6);
    }

    h2 {
      margin: 0 0 45px;
      font-size: 22px;
      color: $colorWhite;
      text-transform: uppercase;
      font-weight: 900;
      @include respons-md {
        margin-bottom: 25px;
        font-size: 20px;
      }
      @include respons-sm {
        margin-bottom: 25px;
        font-size: 18px;
      }
      @include respons-xs {}
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      color: $colorWhite;
      margin: 0 0 20px;
      @include respons-md {
        font-size: 15px;
        margin-bottom: 15px;
      }
      @include respons-sm {
        font-size: 14px;
        margin-bottom: 10px;
      }
      @include respons-xs {}
    }

    .switches {
      margin-bottom: 30px;
      @include respons-md {
        margin-bottom: 25px;
      }
      @include respons-sm {}
      @include respons-xs {}

      .switch {
        padding: 0 18px;
        color: $colorWhite;
        margin-right: 10px;
        position: relative;
        display: inline-block;
        line-height: 42px;
        border: 1px solid $colorWhite;
        cursor: pointer;
        font-size: 16px;
        @include hp_transition();
        @include respons-md {
          padding: 0 12px;
          line-height: 36px;
          margin-right: 5px;
          font-size: 15px;
        }
        @include respons-sm {
          padding: 0 10px;
          line-height: 28px;
          margin-right: 3px;
          font-size: 14px;
        }
        @include respons-xs {
          padding: 0 12px;
          line-height: 36px;
          margin-right: 5px;
          font-size: 15px;
        }

        &:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: 5px;
          background-image: url("../img/pattern_white.png");
          background-size: 8px;
          display: block;
          @include hp_transition();
        }

        &:hover {
          background: $colorWhite;
          color: $colorBlack;
        }

        &.selected {
          border-color: $colorPeach;
          background: $colorPeach;
          color: $colorWhite;

          &:before {
            height: 0;
          }
        }
      }
    }

    .ranger {
      &:after {
        content: "";
        clear: both;
        display: block;
      }

      .range {
        &.ui-slider {
          background: $colorWhite;
          height: 6px;
        }

        .ui-slider-handle {
          background: $colorPeach;
          height: 22px;
          width: 22px;
          margin-left: -11px;
          top: -8px;
          border-radius: 11px;
          outline: 0;
        }

        .ui-slider-range {
          background: $colorPeach;
          opacity: 0.5;
        }
      }

      .left {
        width: 50%;
        text-align: left;
        float: left;
        color: $colorWhite;
        margin-top: 20px;

        span {
          font-weight: 700;
          @include respons-md {
            font-size: 15px;
          }
          @include respons-sm {
            font-size: 14px;
          }
          @include respons-xs {
            font-size: 15px;
          }
        }
      }

      .right {
        width: 50%;
        text-align: right;
        float: left;
        color: $colorWhite;
        margin-top: 20px;

        span {
          font-weight: 700;
          @include respons-md {
            font-size: 15px;
          }
          @include respons-sm {
            font-size: 14px;
          }
          @include respons-xs {
            font-size: 15px;
          }
        }
      }
    }

    button {
      border: 0;
      outline: 0;
      background: $colorPeach;
      border-radius: 26px;
      height: 52px;
      width: 52px;
      line-height: 52px;
      padding: 0;
      text-align: center;
      margin: 25px auto 0;
      display: block;
      @include hp_transition();
      @include respons-sm {
        width: 36px;
        height: 36px;
        line-height: 36px;
      }
      @include respons-xs {
        height: 52px;
        width: 52px;
        line-height: 52px;
      }

      img {
        width: 24px;
        @include respons-sm {
          width: 16px;
        }
        @include respons-xs {
          width: 24px;
        }
      }

      &:hover {
        background: $colorBlack;
      }
    }
  }
}
