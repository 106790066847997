.pages {
  margin-bottom: 60px;
  @include respons-md {
    margin-bottom: 50px;
  }
  @include respons-sm {
    margin-bottom: 40px;
  }

  &__more {
    position: relative;
    display: inline-block;
    margin: 0 0 40px;
    width: 110px;
    text-align: center;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    background: $colorPeach;
    color: $colorWhite !important;
    text-decoration: none !important;
    @include hp_transition();
    @include respons-md {
      line-height: 36px;
      font-size: 15px;
      width: 100px;
    }
    @include respons-sm {
      line-height: 32px;
      font-size: 14px;
      width: 80px;
    }
    @include respons-xs {
      line-height: 36px;
      font-size: 15px;
      width: 110px;
    }

    &:hover {
      background: $colorBlack;
    }
  }

  &__button {
    position: relative;
    display: inline-block;
    margin: 0 4px;
    text-align: center;
    line-height: 32px;
    text-align: center;
    font-size: 16px;
    padding: 0 10px;
    border: 1px solid $colorPeach;
    color: $colorPeach !important;
    text-decoration: none !important;
    @include hp_transition();
    @include respons-md {
      line-height: 24px;
      font-size: 15px;
      padding: 0 8px;
      margin: 0 3px;
    }
    @include respons-sm {
      line-height: 20px;
      font-size: 14px;
      padding: 0 6px;
      margin: 0 2px;
    }
    @include respons-xs {
      line-height: 24px;
      font-size: 15px;
      padding: 0 8px;
      margin: 4px 1px;

      &:first-of-type,
      &:last-of-type {
        display: block;
        width: 90px;
        margin: 4px auto;
      }
    }

    &:hover {
      border-color: $colorBlack;
      color: $colorBlack !important;
    }

    &.active {
      background: $colorPeach;
      color: $colorWhite !important;
      border-color: $colorPeach;
    }
  }
}
