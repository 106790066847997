.header {
  z-index: 999 !important;

  height: 115px;
  line-height: 115px;
  background: $colorWhite;
  @include respons-md {
    height: 105px;
    line-height: 105px;
  }
  @include respons-sm {
    height: 85px;
    line-height: 85px;
  }
  @include respons-xs {
    height: 60px;
    line-height: 60px;
    z-index: 5;
    position: fixed;
    top: 0;
    left: 15px;
    right: 15px;
    @include box-shadow(0, 3px, 2px, rgba(0,0,0,0.1));
  }

  &__logo {
    img {
      width: 210px;
      vertical-align: middle;
      @include respons-md {
        width: 180px;
      }
      @include respons-sm {
        width: 150px;
      }
      @include respons-xs {
        width: 130px;
      }
    }
  }

  #input-search {
    -webkit-appearance: none !important;
    border-radius: 0 !important;
  }
}
