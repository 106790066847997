.catalog {
  margin-bottom: 20px;
  @include respons-md {
    margin-bottom: 20px;
  }
  @include respons-sm {
    margin-bottom: 20px;
  }
  @include respons-xs {}

  &__form {
    margin: 90px -15px;
    @include respons-md {
      margin: 75px -15px;
    }
    @include respons-sm {
      margin: 60px -15px;
    }
    @include respons-xs {
      margin: 40px 0;
    }

    &.works {
      margin: 45px -15px 45px;
      @include respons-md {
        margin: 35px -15px 45px;
      }
      @include respons-sm {
        margin: 30px -15px 45px;
      }
      @include respons-xs {
        margin: 20px 0 40px;
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 15px;
      color: $colorBlack;
      @include respons-md {
        font-size: 15px;
        margin-bottom: 12px;
      }
      @include respons-sm {
        font-size: 14px;
        margin-bottom: 9px;
      }
      @include respons-xs {
        font-size: 16px;
        margin-top: 20px;
      }
    }

    .select {
      h3 { margin-top: 1rem; }
      select {
        border: 2px solid $colorPeach;
        width: 100%;
      }
    }

    .switches {
      .switch__wrapper {
        display: inline-block;
        width: 90%;
        @media screen and (max-width: 767px) {
          width: 20%;
        }
        @media screen and (max-width: 444px) {
          width: 40%;
        }
      }

      &.half {
        &:after {
          content: "";
          display: block;
          clear: both;
        }

        .switch__wrapper {
          display: inline-block;
          width: 48%;
          @media screen and (max-width: 767px) {
            width: 20%;
          }
          @media screen and (max-width: 444px) {
            width: 35%;
          }
        }
        .switch {
          //float: left;
          //width: 24px;
        }
      }

      label {
        width: 70%;
        font-weight: normal;
        cursor: pointer;
        display: inline-block;
        float: right;
      }

      .switch {
        outline: none;
        background: none;
        width: 22px;
        height: 22px;
        margin: 0;
        @include respons-md {
          width: 20px;
          height: 20px;
        }
        @include respons-sm {
          width: 18px;
          height: 18px;
        }
        display: inline-block;
        border: none;
        appearance: none;
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        padding-left: 10px;
        position: relative;
        line-height: 21px;
        margin-bottom: 15px;
        cursor: pointer;
        @include respons-md {
          font-size: 15px;
          line-height: 19px;
          margin-bottom: 12px;
        }
        @include respons-sm {
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 9px;
          padding-left: 5px;
        }
        @include respons-xs {}

        &:before {
          content: "";
          color: $colorPeach;
          font-weight: bold;
          font-size: 2.3rem;
          line-height: 1.5rem;
          padding: 0 0 0 1px;
          display: inline-block;
          position: absolute;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          left: 0;
          top: 0;
          border: 2px solid $colorPeach;
          @include hp_transition();
          @include respons-md {
            width: 20px;
            height: 20px;
          }
          @include respons-sm {
            width: 18px;
            height: 18px;
          }
          @include respons-xs {}
        }

        &:checked {
          &:before {
            content: "✓";
            transition: none;
            font-size: 2.3rem;
            @include respons-md {
              //font-size: 2.1rem;
              //padding-left: 2px;
              line-height: 1rem;
            }
            @include respons-sm {
              font-size: 2.1rem;
              //padding-left: 2px;
              line-height: 1rem;
            }
          }
        }
      }
    }

    .ranger {
      margin-bottom: 20px;

      &:after {
        content: "";
        clear: both;
        display: block;
      }

      .range {
        &.ui-slider {
          background: #ddd;
          height: 6px;
        }

        .ui-slider-handle {
          background: $colorPeach;
          height: 22px;
          width: 22px;
          margin-left: -11px;
          top: -8px;
          border-radius: 50%;
          outline: 0;
          @include respons-md {
            top: -7px;
            margin-left: -10px;
            width: 20px;
            height: 20px;
          }
          @include respons-sm {
            top: -6px;
            margin-left: -9px;
            width: 18px;
            height: 18px;
          }
          @include respons-xs {}
        }

        .ui-slider-range {
          background: $colorPeach;
          opacity: 0.5;
        }
      }

      .left {
        width: 50%;
        text-align: left;
        float: left;
        color: $colorBlack;
        margin-top: 20px;

        span {
          font-weight: 700;
        }
        @include respons-md {
          font-size: 15px;
        }
        @include respons-sm {
          font-size: 14px;
        }
        @include respons-xs {}
      }

      .right {
        width: 50%;
        text-align: right;
        float: left;
        color: $colorBlack;
        margin-top: 20px;

        span {
          font-weight: 700;
        }
        @include respons-md {
          font-size: 15px;
        }
        @include respons-sm {
          font-size: 14px;
        }
        @include respons-xs {}
      }
    }

    button, input[type="submit"] {
      display: block;
      width: 100%;
      max-width: 220px;
      padding: 0;
      line-height: 42px;
      height: 42px;
      margin: 40px auto;
      position: relative;
      border: 1px solid $colorBlack;
      font-size: 16px;
      background: $colorWhite;
      color: $colorBlack;
      @include hp_transition();
      @include respons-md {
        max-width: 180px;
        line-height: 36px;
        font-size: 15px;
        height: 36px;
      }
      @include respons-sm {
        max-width: 140px;
        line-height: 32px;
        font-size: 14px;
        height: 32px;
      }
      @include respons-xs {
        max-width: 180px;
        line-height: 36px;
        font-size: 15px;
        height: 36px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 5px;
        background-image: url("../img/pattern.png");
        background-size: 8px;
        display: block;
        @include hp_transition();
      }

      &:hover {
        color: $colorPeach;
        border-color: $colorPeach;

        &:after {
          background-image: url("../img/pattern_peach.png");
        }
      }

      &.peach {
        background: $colorPeach;
        color: $colorWhite;
        border-color: $colorPeach;
        font-weight: 700;

        &:after {
          background-image: url("../img/pattern_peach.png");
        }

        &:hover {
          background: $colorBlack;
          border-color: $colorBlack;

          &:after {
            background-image: url("../img/pattern.png");
          }
        }
      }
    }
  }

  &__order {
    margin-bottom: 20px;
    @include respons-xs {
      text-align: center;
    }

    a {
      cursor: pointer;
      font-size: 26px;
      font-weight: 900;
      color: $colorBlack !important;
      text-decoration: none !important;
      padding: 0 12px 10px;
      display: inline-block;
      border-bottom: 1px solid transparent;
      @include hp_transition();
      @include respons-md {
        font-size: 23px;
        padding: 0 10px 8px;
      }
      @include respons-sm {
        font-size: 20px;
        padding: 0 8px 6px;
      }
      @include respons-xs {
        margin-bottom: 10px;
        padding: 0 8px 4px;
      }

      &:hover {
        color: $colorPeach !important;
      }

      &.active {
        color: $colorPeach !important;
        border-bottom-color: $colorPeach;
      }
    }

    ._filter-print-link {
      @media (min-width: 768px) {
        float: right;
      }
    }

    .filter-print-link {
      background: none!important;
      border: none;
      cursor: pointer;
      color: $colorBlack !important;
      text-decoration: none !important;
      padding: 0 12px 10px;
      display: inline-block;
      border-bottom: 1px solid transparent;
      font-size: 18px;
      font-weight: normal;

      &:hover {
        color: $colorPeach !important;
      }

      &.active {
        color: $colorPeach !important;
        border-bottom-color: $colorPeach;
      }

      @media (min-width: $screen-md-min) {
        float: right;
        padding-top: 5px;
      }
    }
  }

  &__cover {
    margin: 0 -15px 10px;
    height: 270px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;
    @include hp_transition();
    @include respons-md {
      height: 235px;
    }
    @include respons-sm {
      height: 210px;
    }
    @include respons-xs {}

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $colorPeach;
      z-index: 0;
      opacity: 0;
      @include hp_transition();
    }

    h3 {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px 16px;
      background: rgba(255,255,255,0.6);
      color: $colorBlack;
      font-size: 24px;
      font-weight: 900;
      margin: 0;
      z-index: 1;
      height: 8.7rem;
      display: flex;
      align-items: flex-end;

      @include respons-md {
        height: 8rem;
        font-size: 22px;
        padding: 15px 15px;
      }

      @include respons-sm {
        height: 6.5rem;
        font-size: 18px;
        padding: 12px 15px;
      }

      @include respons-xs {}

      a {
        color: $colorBlack;
        &:hover {
          color: $colorPeach;
          text-decoration: none;
        }
      }
    }
  }

  &__item {
    padding-bottom: 45px;
    position: relative;
    background: #f8f8f8;
    margin-top: 12px;
    margin-bottom: 12px;
    border-right: 1px solid $colorWhite;
    @include hp_transition();
    @include respons-xs {
      border-left: 15px solid $colorWhite;
      border-right: 15px solid $colorWhite;
    }

    &:nth-of-type(3n) {
      border-right: 0;
    }

    &:nth-of-type(4n) {
      clear: left;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 20px;
      background-color: $colorWhite;
      background-image: url("../img/pattern.png");
      background-size: 8px;
      background-position: center center;
      @include hp_transition();
    }

    &__list {
      width: 50%;
      float: left;
      margin-top: 10px;
      @include respons-sm {
        margin-top: 18px;
      }

      h4 {
        margin: 0 0 5px;
        font-size: 16px;
        font-weight: 700;
        color: $colorBlack;
        @include respons-md {
          font-size: 15px;
        }
        @include respons-sm {
          font-size: 14px;
        }
        @include respons-xs {}
      }

      span {
        font-size: 16px;
        color: $colorPeach;
        @include respons-md {
          font-size: 15px;
        }
        @include respons-sm {
          font-size: 14px;
        }
        @include respons-xs {}
      }

      img {
        width: 25px;
        margin-right: 14px;
        cursor: help;
        @include respons-md {
          width: 20px;
          margin-right: 12px;
        }
        @include respons-sm {
          width: 18px;
          margin-right: 9px;
        }
        @include respons-xs {}
      }
    }

    &__price {
      text-align: center;
      color: $colorBlack;

      span {
        font-size: 16px;
        @include respons-md {
          font-size: 15px;
        }
        @include respons-sm {
          font-size: 14px;
        }
        @include respons-xs {}
      }

      h4 {
        margin: 0;
        font-size: 24px;
        font-weight: 900;
        @include respons-md {
          font-size: 22px;
        }
        @include respons-sm {
          font-size: 20px;
        }
      }
    }

    p {
      margin: 0 0 15px;
      padding-top: 20px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 300;
      clear: both;
      height: 12rem;
      @include hp_transition();
      @include respons-md {
        font-size: 14px;
        padding-top: 20px;
        line-height: 18px;
      }
      @include respons-sm {
        font-size: 14px;
        padding-top: 20px;
        line-height: 17px;
        height: 14rem;
      }
      @include respons-xs {
        height: auto;
      }
    }

    &:hover {
      margin-bottom: 0;
      margin-top: 0;
      padding-bottom: 45px;

      &:after {
        background-image: url("../img/pattern_peach.png");
        bottom: -10px;
      }

      .catalog__cover {
        height: 280px;
        @include respons-md {
          height: 245px;
        }
        @include respons-sm {
          height: 220px;
        }
        @include respons-xs {}

        &:before {
          opacity: 0.5;
        }
      }
    }
  }

  &__more {
    position: relative;
    display: block;
    margin: 18px auto 0;
    width: 110px;
    text-align: center;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    background: $colorPeach;
    color: $colorWhite !important;
    text-decoration: none !important;
    @include hp_transition();
    @include respons-md {
      line-height: 36px;
      font-size: 15px;
      width: 100px;
    }
    @include respons-sm {
      line-height: 32px;
      font-size: 14px;
      width: 80px;
    }
    @include respons-xs {
      line-height: 36px;
      font-size: 15px;
      width: 110px;
    }

    &:hover {
      background: $colorBlack;
    }
  }

  &__empty {
    h3 {
      text-align: center;
    }
  }
}

.product-list-table {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  table-layout: fixed;

  thead {
    background-color: rgba(125,125,125, 0.1);
    h4 {
      text-align: center;
    }
  }
  td {
    border-bottom: 1px solid lightgray;
    padding: 5px;
    text-align: center;

    &:first-of-type {
      padding-left: 20px;
    }

    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }

    &.product-thumbnail {
      img {
        width: 100%;
        height: auto;
      }
    }

    &.product-title {
      padding-right: 1rem;
      a {
        color: #ff8060;
        font-size: 1.6rem;
        &:hover {
          color: black;
        }
      }
    }
  }
}

@media print {
  a:after { content:'' !important; }
  a[href]:after { content: none !important; }
}
